'use strict';

import AjaxFormErrorHandler from 'Scripts/common/ajax-form-error-handler';
import ImageUploadCrop      from 'Scripts/common/image-upload-crop';
import LoadingButton        from 'Scripts/common/loading-button';

export default class CharityImages {
	constructor() {
		this.errorHandler  = new AjaxFormErrorHandler({ useClasses: true });
		
		this.form            = $('.js-charity-images-form');
		this.submitContainer = $(".js-submit-container");
		this.submitBtn       = new LoadingButton(this.form.find('.js-submit'));

		this.banner = new ImageUploadCrop({
			width:     1170,
			height:    350,
			restrict:  true,
			container: $('.js-banner-container'),
			modal:     $("#js-modal-image-crop-banner"),
			callback:  this.ready.bind(this),
		});

		this.logo = new ImageUploadCrop({
			width:     320,
			height:    240,
			flexible:  true, // Allows images that are a bit smaller than the dimensions
			restrict:  false,
			container: $('.js-logo-container'),
			modal:     $("#js-modal-image-crop-logo"),
			callback:  this.ready.bind(this),
		});

		this.bindEvents();
	}

	bindEvents() {
		this.form.on('submit', this.onSubmit.bind(this));
	}

	ready() {
		this.submitContainer.collapse("show");
	}

	onSubmit(event) {
		event.preventDefault();

		let endpoint = this.form.attr('action');
		let formData = new FormData(this.form.get(0));

		formData = this.banner.addImagesToFormData(formData, "banner");
		formData = this.logo.addImagesToFormData(formData, "logo");
		
		this.submitBtn.disable();

		return $.ajax({
			url: endpoint,
			method: 'POST',
			data: formData,
            processData: false,
            contentType: false
		}).then(resp => {
			if(!resp.success) {
				this.errorHandler.handleErrors(resp.payload.validation);
				return;
			}
			window.location.href = "/dashboard";
		}).catch(error => {
			// I couldn't find a way to handle image upload size exceeded gracefully (without writing JS to validate the file size before upload).
			// It's really crap it doesn't fall within the Spring validation step so it could all be handled the same way...
			if(error.status === 502) {
				this.errorHandler.handleErrors({
					errors: [
						{
							fieldName: 'banner',
							message: 'Image must be under 10MB'
						},
						{
							fieldName: 'logo',
							message: 'Image must be under 10MB'
						}
					]
				})
			}
		}).always(() => {
			this.submitBtn.enable();
		});
	}
}