// Javascript for charity submission of donation reasons for approval
// @author Matthew Norris

import Fetcher        from "Scripts/common/fetcher";
import Form           from "Scripts/common/form";
import TextAreaExpand from 'Scripts/common/text-area-expand';

export default class CharityDonationReason {
	constructor() {
		this.list = new Fetcher({
			element:  "#js-reasons-fetcher",
			callback: this.bindButtons.bind(this),
		});

		this.modal        = $("#donation-reason-modal");
		this.deleteModal  = $("#donation-reason-delete-modal");

		this.bindButtons();
	}

	bindButtons() {
		$(".js-donation-reason-create").on('click', this.showEditModal.bind(this));
		$(".js-donation-reason-edit").on('click', this.showEditModal.bind(this));
		$(".js-donation-reason-delete").on('click', this.showDeleteModal.bind(this));
	}
		
	// Display modal where donation reasons can be created/edited
	showEditModal(e) {
		console.log("CharityDonationReason.setupEditForm()", $(e.currentTarget).data('amount'));

		new Fetcher({
			callback: this.setupEditForm.bind(this),
			data:     $(e.currentTarget).data(), // Includes the ID (if editing)
			element:  "#js-edit-reason-modal-fetcher",
			modal:    this.modal,
			trigger:  $(e.currentTarget)
		});
	}

	setupEditForm() {
		console.log("CharityDonationReason.setupEditForm()");

		new Form({
			callback: this.submitModal.bind(this),
			form:     $("#donation-reason-form"),
		});

		// Get inputs
		this.amount         = $(".js-amount");
		this.setAmount      = $(".js-set-amount");
		this.setAmountGroup = $(".js-set-amount-group");
		this.text           = $(".js-reason");

		// Bind events
		this.amount.on('change', this.onAmountChanged.bind(this));
		this.setAmount.on('click', this.toggleAmount.bind(this));
		this.text.on('input', this.updateCharCount.bind(this)).trigger('input');

		// This makes <textarea> inputs expand as you type
		new TextAreaExpand();
	}

	// Display confirm modal for donation reason deletion
	showDeleteModal(e) {
		console.log("CharityDonationReason.showDeleteModal()", $(e.currentTarget).data('amount'));

		new Fetcher({
			callback: this.setupDeleteForm.bind(this),
			data:     $(e.currentTarget).data(), // Includes the ID (if editing)
			element:  "#js-delete-reason-modal-fetcher",
			modal:    this.deleteModal,
		});
	}

	setupDeleteForm() {
		console.log("CharityDonationReason.setupDeleteForm()");

		new Form({
			callback: this.submitModal.bind(this),
			form:     $("#donation-reason-form-delete"),
		});
	}

	submitModal() {
		console.log("CharityDonationReason.submitModal");
		this.modal.modal("hide");
		this.deleteModal.modal("hide");
		this.list.reset();
	}

	// Manually enter a donation amount
	onAmountChanged(e) {
		console.log("DonationReason.onAmountChanged()");
		this.setAmountCss(this.amount.val());
	}

	// Choose a preset donation amount
	toggleAmount(e) {
		console.log("DonationReason.toggleAmount()", $(e.currentTarget).data().value);
		let value = $(e.currentTarget).data().value;

		this.setAmountCss(value);

		// Update input field + remove
		this.amount.val(value);
	}

	// Set the CSS classes for the amount boxes
	setAmountCss(value) {
		console.log("DonationReason.setAmountCss()", value);

		let buttonActive = "btn-navy";
		let buttonInactive = "btn-silver";

		// Remove any active state
		this.setAmountGroup.find('.js-set-amount').removeClass(buttonActive).addClass(buttonInactive);

		// Add active state (because of dynamic selector, we filter out bad values using regex)
		if(value.toString().match(/^\d+$/g)){
			this.setAmountGroup.find('.js-set-amount-' + value).removeClass(buttonInactive).addClass(buttonActive);
		}
	}

	updateCharCount(e) {
		console.log("DonationReason.updateCharCount()", e.target.value.length);

		let count = e.target.value.length;
		let countText = $(".js-reason-length");
		let max = parseInt(countText.data('max'));
		let perc = count / max;

		countText.html(count);
		countText.toggleClass("text-orange", perc > 0.75 && perc < 1);
		countText.toggleClass("text-red", perc >= 1);
	}
}
