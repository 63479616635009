// JS for external admin
// @author Matthew Norris

// Import SASS/SCSS styles
import 'Sass/admin.scss';

// Import common modules
const Animate                = require("Scripts/common/animate");
const CharityChooser         = require('Scripts/common/charity-chooser');
const DropdownMenu           = require('Scripts/common/nav-dropdown');
const FormUtils              = require('Scripts/common/form-utils');
const Global                 = require('Scripts/common/global');
const Fees                   = require('Scripts/common/fees');
const Fetcher                = require('Scripts/common/fetcher');
const Loader                 = require('Scripts/common/loader');
const LoginSignup            = require('Scripts/common/login-signup');
const MobileMenu             = require('Scripts/common/nav-mobile');
const OwlCarousel            = require('Scripts/common/owl-carousel');
const Share                  = require('Scripts/common/share');
const ShowHide               = require('Scripts/common/showhide');
const SupportTicket          = require('Scripts/common/support-ticket');

// Admin Pages
const AppPinForm             = require('Scripts/admin/app-pin-form');
const BankDetails            = require('Scripts/admin/bank-details');
const CampaignForm           = require('Scripts/admin/campaign/campaign-form');
const CampaignClose          = require('Scripts/admin/campaign/campaign-close');
const ChangeEmail            = require('Scripts/admin/change-email');
const ChangePersonalDetails  = require('Scripts/admin/change-personal-details');
const CharityDetails         = require('Scripts/admin/charity-details');
const CharityDonationReason  = require('Scripts/admin/charity-donation-reason');
const CharityEvent           = require('Scripts/admin/charity-event');
const CharityImages          = require('Scripts/admin/charity-images');
const CharityUrl             = require('Scripts/admin/charity-url');
const ComplianceAgreement    = require('Scripts/admin/compliance-agreement');
const Contacts               = require('Scripts/admin/contacts');
const CustomCodes            = require('Scripts/admin/custom-codes');
const ForgotPassword         = require('Scripts/admin/forgot-password');
const GiftAid                = require('Scripts/admin/giftaid');
const GlobalAdmin            = require('Scripts/admin/global');
const Register               = require('Scripts/admin/register');
const ResetPassword			 = require('Scripts/admin/reset-password')
const SetPassword            = require('Scripts/admin/set-password');
const StripeSetup            = require('Scripts/admin/stripe-setup');
const TicketAgendaForm       = require('Scripts/admin/ticketing/ticket-agenda-form');
const TicketDiscountCodeForm = require('Scripts/admin/ticketing/ticket-discount-code-form');
const TicketTypesForm        = require('Scripts/admin/ticketing/ticket-types-form');
const TicketingForm          = require('Scripts/admin/ticketing/ticketing-form');
const TicketingClose         = require('Scripts/admin/ticketing/ticketing-close');

// Borrowed from GAYL Donate
const CharitySplits          = require('Scripts/donate/charity-splits');
const OfflineAmountsModal    = require('Scripts/donate/offline-amounts-modal');
const PageUpdateModal        = require('Scripts/donate/page-update-modal');

// Define groups of modules - the group name is set in your template ($jsModuleGroup). 
// This is just a nicer way to load JS on specific pages without looking at the path/params
// To initiate a group, use the #module macro. E.g. #module("my-group") would add 'my-group' from the groups below.
const moduleGroups = {

	// Available on every page
	'global': [
		Global, 
		GlobalAdmin, 
		DropdownMenu, 
		Fetcher, 
		MobileMenu, 
		OwlCarousel, 
		ShowHide
	],

	// Available on specific pages
	'animate':                 [Animate],
    'app-pin':                 [AppPinForm],
	'bank-details':            [BankDetails],
	'campaign-form':           [CampaignForm],
	'campaign-tools':          [CampaignClose, OfflineAmountsModal, PageUpdateModal, Share],
	'change-email':            [ChangeEmail],
	'change-personal-details': [ChangePersonalDetails],
	'charity-chooser':         [CharityChooser],
	'charity-details':         [CharityDetails],
	'charity-donation-reason': [CharityDonationReason],
	'charity-event':           [CharityEvent],
	'charity-images':          [CharityImages],
	'charity-splits':          [CharitySplits],
	'charity-url':             [CharityUrl],
	'compliance-agreement':    [ComplianceAgreement],
	'contacts':                [Contacts],
	'custom-codes':            [CustomCodes],
	'fees':                    [Fees],
	'forgot-password':         [ForgotPassword],
	'form-utils':              [FormUtils],
	'giftaid':                 [GiftAid],
	'login':                   [LoginSignup],
	'register':                [Register],
	'reset-password': 		   [ResetPassword],
	'set-password':            [SetPassword],
	'stripe-setup':            [StripeSetup],
	'support-ticket':          [SupportTicket],
	'ticketing-form':          [TicketingForm, TicketAgendaForm, TicketDiscountCodeForm, TicketTypesForm],
	'ticketing-tools':         [OfflineAmountsModal, TicketingClose, PageUpdateModal, Share],
};

Loader.load(moduleGroups);
